export const FirebaseConfig = {
  apiKey: "AIzaSyBdC_Pk3zbZvITUuf4ewUt6a2Zka-yzs8o",
  authDomain: "auctionhelper88.firebaseapp.com",
  databaseURL: "https://auctionhelper88.firebaseio.com",

		// these ones were not part of the git-repo, but came with firebase database creation:
	projectId: "auctionhelper88",
	storageBucket: "",
	messagingSenderId: "918776073511",  
};


