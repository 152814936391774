import React, { Component } from "react";
import { connect } from "react-redux";

import NumberFormat from 'react-number-format';

class BidListItem extends Component {


  render() {
    const { itemId, item } = this.props;

    if (!!!item || !!!item.maxBid) {
    	return null
    }

    return (
            <tr>
              <td>{new Date(item.time).toLocaleString()}</td>
              <td>{item.bidder}</td>
              <td><NumberFormat value={item.maxBid} displayType={'text'} thousandSeparator={true} suffix={',000'} /></td>
            </tr>
    )
  }
}




const mapStateToProps = ({ auth }) => {
  return {
    auth
  };
};

export default connect(mapStateToProps)(BidListItem);
