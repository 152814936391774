
if (process.env.NODE_ENV === "production") {
  //module.exports = require("./prod");
  module.exports = require("./dev");

} else {
  module.exports = require("./dev");
}

/*
const functions = require('firebase-functions')

//functions.config().someservice.id

const firebaseEnvObj = functions.config().ah


export const FirebaseConfig = {
	apiKey: firebaseEnvObj.apikey,
	authDomain: firebaseEnvObj.authdomain,
	databaseURL: firebaseEnvObj.databaseurl,

	// these ones were not part of the git-repo, but came with firebase database creation:
	projectId: firebaseEnvObj.projectid,
	storageBucket: "",
	messagingSenderId: firebaseEnvObj.messagingsenderid,  
}

*/

//module.exports = require("./dev");
