//import "./ToDoList.css";
import React, { Component } from "react";


//import Modal from 'react-awesome-modal';
//import Cleave from 'cleave.js/react';

import NumberFormat from 'react-number-format'

import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';


import ReactResponsiveSelect from 'react-responsive-select'


import 'react-responsive-select/dist/ReactResponsiveSelect.css'

const caretIcon = (
  <svg className="caret-icon" x="0px" y="0px" width="11.848px" height="6.338px" viewBox="351.584 2118.292 11.848 6.338">
    <g><path d="M363.311,2118.414c-0.164-0.163-0.429-0.163-0.592,0l-5.205,5.216l-5.215-5.216c-0.163-0.163-0.429-0.163-0.592,0s-0.163,0.429,0,0.592l5.501,5.501c0.082,0.082,0.184,0.123,0.296,0.123c0.103,0,0.215-0.041,0.296-0.123l5.501-5.501C363.474,2118.843,363.474,2118.577,363.311,2118.414L363.311,2118.414z"/></g>
  </svg>
);



class FinalPrice extends Component {

  constructor(props) {
  	
		super(props)

		this.state = {

			price: 0,
			priceNice: '',

			bidders:[],	// these come in via props, will be removed from state shortly
			items:[],	// these come in via props, will be removed from state shortly 

			bidder: '',
			itemNr: '',

			image: '',
			title: '',

			finalPriceWin: false	// indicates if we should set the price into the field or not..

		}

		//this.modalRef = React.createRef()

	}


	formSubmit = (e) => {
		e.preventDefault()
		if (this.state.bidder.length > 0 && this.state.price > 0 && this.state.itemNr.length > 0 ) {
			this.props.closeFBmodal()
			document.activeElement.blur()
			console.log('formSubmit..') 
			console.log('should submit values via props function..')
			console.log(this.state)
			this.props.onSubmit(this.state)	

		} else {
			let missing = ''
			if (this.state.bidder.length == 0 ) {
				missing += ", Bidder"
			}
			if (this.state.price == '' ) {
				missing += ", Price"
			}		
			if (this.state.itemNr.length == 0 ) {
				missing += ", KoiNr"
			}	
			if (missing.length > 0) {
				missing = missing.slice(2)
			}

			this.props.onMessage({severity: 'error', summary: 'Missing Fields:', detail: missing }) 
		}

	}



	// for general variables, not handled by Cleave add-on
	handleInputChange = (event, field) => {

		const value = event.target.value

		this.setState({ [field]: value })
	}


	// if ENTER, fetch the image, otherwise just set state with event value
	handleItemNrChange = (event) => {
		const key = event.key || event.keyCode || event.charCode
		if (key == 13) {	// this is not working in pc browser (chrome and ff)
			console.log('got enter key..')
			this.fetchImage()
			return
		}
		this.setState({ itemNr: event.target.value, finalPriceWin: false })
	}


	// get image for the item nr entered...
	fetchImage = async () => {

		if  (!!this.state.itemNr && this.state.itemNr > 0) {

			const image = await this.props.getImageForNr(this.state.itemNr)
			if (!!image) {
				console.log('got image in catalog for this nr:', image)
				this.setState({ image: image })
			} else {
				this.props.onMessage({severity: 'error', summary: 'No image', detail: 'for item Nr. '+this.state.itemNr })			
				this.setState({ image: '/img/no-image.png', itemNr: '' })
				console.log('!! no image for this number in catalog..:', this.state.itemNr)
			}			
		}

	}


	// for Cleave add-on variables
	handlePriceChange = (event) => {
	
	  this.setState({ price: event.target.rawValue, priceNice: event.target.value });

	}


	// for Cleave add-on variables  && not really needed..
	onPriceFocus = (event) => {
	  // update some state
	  console.log('focus is on price..')
	}


	componentDidUpdate(prevProps, prevState) {

		// only when visibleFB props is changed (from false to true), put focus on some input field
		// the id of that field comes in via focusFieldID props.



		if (!!this.props.visibleFB && prevProps.visibleFB !== this.props.visibleFB && this.props.visibleFB) {

			const focusFieldID = !!this.props.focusOn? this.props.focusOn + "FB" : "bidderFB"

			// put focus on a certain input field (bidder, price, itemNr)
			document.getElementById(focusFieldID).focus()

		}

		// if values props are different, update state..
		if (!!this.props.values && prevProps.values !== this.props.values) {
			console.log('new values.. should re-render..:', this.props.values)

			const { values } = this.props

			if (!!!values ) {
				return
			}

			const { price, bidder, itemNr, image, title, bidders, showBidders, finalPriceWin } = values

			

			this.setState( {
				priceNice: '',
				price: !!price ? price : '',
				bidder: !!bidder ? bidder : '',
				bidders: !!bidders ? bidders: [],
				itemNr: !!itemNr ? itemNr : '',
				image: !!image ? image : '',
				showBidders: !!showBidders ? showBidders : false,
				title: !!title ? title : '',
				finalPriceWin: !! finalPriceWin ? finalPriceWin : false		
			})
			

		}

	}


	componentWillMount() {
		
	}

	//props: { visibleFB, onFBsubmit, values: { price, bidder, itemNr } }


	componentDidMount() {

	}

	onBidderChange =(e) => {
		console.log('onBidderChange:', e)
		console.log('onBidderChange e.value:', e.value)
		this.setState({bidder: e.value});
	}




  render() {

  		console.log('render final price..')


		
 	    //$( '#inputField' ).focus()

 	// only render the bidder selection, if user is privileged to see them..
 	const renderBidderSelection = this.state.showBidders ? (<ReactResponsiveSelect
				  name="finalprice"
				  options={this.state.bidders}
				  selectedValue={this.state.bidder}				  
				  onChange={this.onBidderChange}
				  caretIcon={caretIcon}
				  style={{ backgroundColor: this.props.values.bgColor }}
				/>) : ''


    return (
		<Sidebar visible={this.props.visibleFB} 
			position={this.props.values.direction} 
			onHide={ this.props.closeFBmodal }
			className="ui-sidebar-md"
			style={{ backgroundColor: this.props.values.bgColor }}
		>


      		<form onSubmit={(e) => this.formSubmit(e) }>
             

			    <div className="p-col" style={{ marginBottom: "0.2em"}}>
			        	<h3>{this.state.title}</h3>
			    </div>


			    { renderBidderSelection }


			    <br />


			    <div className="p-float-label">
	                  <input id="bidderFB" type="text" 
	                  	style={{ backgroundColor: this.props.values.bgColor, textAlign: "center", fontSize: "1.3em", width: "100%" }}
						value={this.state.bidder}
						onChange={(e) => this.handleInputChange(e, "bidder")}
					  />
	                  <label htmlFor="bidderFB">Bidder</label>
	            </div>

	            <br />


	            <div className="p-float-label">
                    <NumberFormat value={this.state.price} 
                      id="finalPrice"
                      style={{ backgroundColor: this.props.values.bgColor, textAlign: "right", fontSize: "1.3em", width: "100%" }}
                      type={'tel'}
                      displayType={'input'}  
                      thousandSeparator={true} prefix={'¥ '} 
                      suffix={',000'} 
                      onValueChange={(values) => {
                        const {formattedValue, value} = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        this.setState({ price: value })
                      }}
                    />
                   	<label htmlFor="finalPrice">{this.props.values.priceTitle}</label>
	            </div>

	            <br />


	            <div className="p-float-label">
					<input id="itemNrFB" type="number" 
						className="validate right" 
						style={{ backgroundColor: this.props.values.bgColor, textAlign: "center", fontSize: "1.3em", width: "100%" }} 
						value={this.state.itemNr}
						onChange={ this.handleItemNrChange } 
						onBlur={ this.fetchImage }
					/>                  
					<label htmlFor="itemNr">Koi Nr.</label>
	            </div>

	            <br />


	            <div className="p-col">
	                <img src={ this.state.image } alt="FinalPriceKoi" height="150px" width="auto" style={cssImageCenter} />

	                <br />

	                <Button
						onClick={(e) => this.formSubmit(e)}
						label="Ok" icon="pi pi-plus-circle"
						className="p-button-primary p-button-raised p-button-rounded"
						style={{ width:"100%"}}
					/>
	            </div>	            	            

			</form>





		</Sidebar>
    )	// return
  }
}


export default FinalPrice



const cssImageCenter = {
	display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "auto",
    height: "150px",
    zIndex: "20000",
}



/*

				<div className="p-col">
                    <Dropdown 
                    	value={this.state.bidder} 
                    	options={bidders} 
                    	onChange={this.onBidderChange}

                        editable={true} 
                        placeholder="Select a bidder"
                        style={{ width: "100%", backgroundColor: "lightBlue", fontSize: "1.6em" }}
                    />
                    <div style={{marginTop: '.5em'}}>
                    	{this.state.bidder ? 'Selected Bidder: ' + this.state.bidder : 'No bidder selected'}
                    </div>
			    </div>



                <div className="input-field inline" style={{ width:`calc(100% - 5em)` }}>
                    <label>Código de Barras</label>
                    <input type="text" placeholder="" style={{ textAlign: "right", fontSize: "24px" }} />
                </div>
                <div className="right" style={{ width:`50px`, fontSize: "15px", paddingTop: "2.2em" }}>
                     ,000
                </div>





          <button
            onClick={() => this.setState({ addFormVisible: !addFormVisible })}
            className="btn-floating btn-large teal darken-4"
          >
            {addFormVisible ? (
              <i className="large material-icons">close</i>
            ) : (
              <i className="large material-icons">add</i>
            )}
          </button>


          <button
            onClick={() => this.setState({ activeItemFormVisible: !activeItemFormVisible })}
            className="btn-floating btn-large teal darken-4"
          >
            {activeItemFormVisible ? (
              <i className="large material-icons">close</i>
            ) : (
              <i className="large material-icons">Item</i>
            )}
          </button>

*/


/*
			    <div className="p-col">
                    <Dropdown 
                    	value={this.state.bidder} 
                    	options={this.state.bidders} 
                    	onChange={this.onBidderChange}

                        editable={false} 
                        placeholder="Select a bidder"
                        style={{ width: "100%", backgroundColor: "lightBlue", fontSize: "1.6em" }}
                    />
			    </div>

			    */