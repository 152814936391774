import "./ToDoList.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "../actions";
import { fetchCatalogItemOnce, addFinalPrice, addMaxBid, getImageFromCatalog, getAuctions, getAllBidders } from "../actions"
//import ToDoListItem from "./ToDoListItem";
import BidListItem from "./BidListItem"
import Preloader from "./Preloader";
import BidListDrawer from './BidListDrawer'

// for touch and mouse events

import Modal from 'react-awesome-modal';
import Cleave from 'cleave.js/react';

import FinalPrice from './FinalPrice'
import BidAdd from './BidAdd'

import { Button } from 'primereact/button';
import { Message } from 'primereact/message';


import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import {Growl} from 'primereact/growl';

import { Dropdown } from 'primereact/dropdown';
import NumberFormat from 'react-number-format'


const FORM_FONT_SIZE = "1.1em"
const INPUT_FONT_SIZE = "1.5em"
const HEADER_BLOCK_SIZE = "5em"
const FOOTER_BLOCK_SIZE = "2.7em"


/*
          style={{  backgroundImage: "url(" + "http://www.oldboy-village.com/momotaro-koi/upload/carp/41/930_thum_j.jpg" + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',}}
*/


class BidList extends Component {
  state = {

    itemKeyInput: '', // for koi nr input

    dataViewedTS: 0, // last timestamp of data update viewed

    // to remember...  but got this in redux.data.id
    auctionKey:"sakai-2018-12-5", 
    breederKey:"sakai", 
    itemKey:"1",
    image:"",

    visibleFB: false, // toggle enter final bid dialog
    visibleADD: false, // toggle Add maxBid dialog
    visibleBIDS: false, // toggle visiblity of bids for koi item

    auctions: [], // list of all auctions in DB
    bidders: [], // list of all bidders in DB



  }


  // close the FinalPrice / WIN Modal (), called by the modal component (on form submit, click outside, ...)
  closeFBmodal = (e) => {
    //e.preventDefault()
    this.setState({
        visibleFB : false
    })
  }

  // open the FinalPrice / WIN Modal (), called by BUTTON that triggers dialog
  openFBmodal = (e, win=false) => {
    //e.preventDefault()
    this.setState({
        visibleFB : true,
        finalPriceWin: win
    })
  }


  //  submit Final Price / WIN values (flag WIN if my customer won..``)
  onFBsubmit = (values) => {
    console.log('!! received final price from component..:', values)

      const addRes = addFinalPrice({ 
        auctionKey: this.state.auctionKey, 
        breederKey: this.state.breederKey, 
        itemKey: values.itemNr,
        bidderKey: values.bidder, 
        finalPrice: values.price,
        finalPriceWin: values.finalPriceWin })      

      this.onMessage({severity: 'success', summary: 'Price Added', detail: 'ok'})        

  
  }




  // close the Add maxBid Modal (), called by the modal component (on form submit, click outside, ...)
  closeADDmodal = (e) => {
    //e.preventDefault()
    this.setState({
        visibleADD : false
    })
  }

  // open the Add maxBid Modal (), called by BUTTON that triggers dialog
  openADDmodal = (e) => {
    //e.preventDefault()

    this.setState({
        visibleADD : true
    })
  }

  // submit maxBid values
  onADDsubmit = (values, win=false) => {
    console.log('!! received final price from component..:', values)
    // getting these things in the object: bidder, image, itemNr, price, 
  

      const addRes = addMaxBid({ 
        auctionKey: this.state.auctionKey, 
        breederKey: this.state.breederKey, 
        itemKey: values.itemNr,
        bidderKey: values.bidder, 
        maxBid: values.price      // not finalPrice in here !!!
      })




      this.onMessage({severity: 'success', summary: 'Bid Added', detail: 'ok'})
    
  }



  // not used with visibleBidsDrawer, can be retired if i stick with the drawer..
  toggleVisibleBids = () => {


    if (!!this.props.data && !!this.props.data.sortedArr && this.props.data.sortedArr.length > 0) {
      // got maxBid data

      // remember time stamp that data was viewed

      // data is not viewed yet, but will be viewed at end of function, remember timestamp of viewing
      if (!!!this.state.visibleBIDS) {
        this.setState({ dataViewedTS: this.props.data.received })
        //this.setState({ dataViewedTS: Date.now() })
      }



    } else {
      // no maxBid data
      if ((!this.state.visibleBIDS)) {
        this.onMessage({severity: 'warn', summary: 'No data', detail: 'No maxBids for this item..'})
      } else {
        this.onMessageClear()
      }
      
    }

    this.setState(prevState => ({
      visibleBIDS: !prevState.visibleBIDS
    }))

  }



  openVisibleBidsDrawer = (e) => {
    e.preventDefault()

    if (!!this.props.data && !!this.props.data.sortedArr && this.props.data.sortedArr.length > 0) {
      // got maxBid data

      // data is not viewed yet, but will be viewed at end of function, remember timestamp of viewing
      this.setState({ dataViewedTS: this.props.data.received, visibleBIDS: true })
      return
    }

    this.setState({ visibleBIDS: true })


  }

  closeVisibleBidsDrawer = () => {


    if (!!this.props.data && !!this.props.data.sortedArr && this.props.data.sortedArr.length > 0) {
      // got maxBid data

      // data is not viewed yet, but will be viewed at end of function, remember timestamp of viewing
      this.setState({ dataViewedTS: this.props.data.received, visibleBIDS: false })
      return
    
    } else {
      // no maxBid data
      this.onMessage({severity: 'warn', summary: 'No data', detail: 'No maxBids for this item..'})

    }

    this.setState({ visibleBIDS: false })

  }  


  getCatalogItemForNr = async (Nr) => {

      const itemPath = `${this.state.auctionKey}/items/${this.state.breederKey}/${Nr}`

      const catItem = await fetchCatalogItemOnce(itemPath)

      if (!!!catItem) {
        console.log('..no item found in catalog.. stopping!!')
        return false

      } else {

        const image = catItem.image? catItem.image : ''
        const finalPrice = catItem.finalPrice? catItem.finalPrice : ''
        const finalPriceBidder = catItem.bidderKey? catItem.bidderKey : ''
        const finalPriceTime = catItem.time? catItem.time: ''
        const finalPriceWin = catItem.win? catItem.win : false

        this.setState({ 
            image: catItem.image, 
            itemKey: this.state.itemKeyInput, 
            dataViewedTS: this.props.data.received, 
            finalPrice,
            finalPriceBidder,
            finalPriceTime,
            finalPriceWin
        })

        return catItem


      }   
  }


  getImageForNr = async (Nr) => {

      const itemPath = `${this.state.auctionKey}/items/${this.state.breederKey}/${Nr}`

      const image = await getImageFromCatalog(itemPath)

      if (!!!image) {
        console.log('..no image found in catalog.. stopping!!')
        return false
      } else {
        return image
      }   
  }



  handleItemInputChange = (event) => {
    this.setState({ itemKeyInput: event.target.value });
  }




  handleItemFormSubmit = async event => {
    const { itemKeyInput, auctionKey, breederKey } = this.state;

    // load new data for new itemKey 

    event.preventDefault()


    console.log('before fetchBidItem (handleItemFormSubmit)..:', itemKeyInput)

    // get previous item path (to turn off its event listener, and stop getting updates)
    const prevBidItemPath = !!this.props.data && !!this.props.data.id && !!this.props.data.id.prevBidItemPath ? this.props.data.id.prevBidItemPath : ''


    this.setState({ dataViewedTS: 0 })

    // !!!! from maxBids, not the catalog... !!!!!!
    const ok = await this.props.fetchBidItem({ auctionKey, breederKey, "itemKey": itemKeyInput, prevBidItemPath })

    // should move that into redux.. and create the sorted array there..
    //this.calculateHighestBid()  





    if (!!!ok) {  // triggered too early, because its not a post-load result... need to change that in the action..
      console.log('no data or error...')    
      //M.toast({html: 'I am a toast!', classes: 'rounded', displayLength: 1000, });
      //window.alert(`no data for Koi Nr. ${itemKeyInput}`)

      //this.onMessage()


      //const image = await this.getImageForNr(itemKeyInput)
      const catItemOk = await this.getCatalogItemForNr(itemKeyInput)



      document.activeElement.blur()


      //  event.blur()

    } else {

      // loading data was OK, set dataViewedTS to a lower value, before the 'received' redux props

      // set the timestamp to be different than the received-data timestamp, set it to 1 to indicate that we got new data but hasn t been viewed yet..
      this.setState({ dataViewedTS: 1 })

      // don t need this. redux has    data.id.itemKey  for current bid item


    }

    //this.setState({ itemKeyInput: '' })
  }


  // mobile safari needs:    onClick="this.setSelectionRange(0, this.value.length)"
  handleItemFocus = (event) => {
    //document.execCommand("selectall",null,false);
    //this.select()
    event.target.select()
  } 


  renderHeader() {
    const { data } = this.props

    if (!!data.id && data.id) {

      const { auctionKey, breederKey, itemKey } = data.id

      return (
        <div>

  
          { auctionKey }{" "}{ breederKey }{" "}{ itemKey }{" "}
          <br />

          { data.image }

          <br />
          Received: {new Date(data.received).toLocaleTimeString()}


        </div>
      )      
    }

    return null

  }

  // caled when user picks a new / different auction out of the list..
  // should close functions if the auctions are past..
  onAuctionChange = (e) => {

    const auction = e.value

    //console.log('should load new auction data for:', e, this.state.auctionKey )


    const result = this.state.auctions.filter(item => item.value == auction)
    console.log('result:', result)

    this.setState({ auctionKey: auction, breederKey: result[0].breeder })

    // RELOAD CATALOG HERE, AND SET BREEDER  !!!    
  }



  renderAuctionSelector = (list, activeEl) => {


    return(
      <Dropdown 
        value={this.state.auctionKey} 
        options={this.state.auctions} 
        onChange={ this.onAuctionChange }
        placeholder="Choose Auction"
        style={{ width: "100%", fontSize: "1.6em" }}
      />
    )
  }


  renderInputField = () => {

    return(
      <form onSubmit={this.handleItemFormSubmit}>
              <br />
              <div className="p-float-label">
                <input id="itemNrMain" type="tel" 
                  style={{ width: "100%", textAlign: "center", color: "black", fontSize: INPUT_FONT_SIZE, fontWeight: 900, textShadow: "3px 3px 3px #ffffff" }} 
                  onFocus={this.handleItemFocus}
                  value={this.state.itemKeyInput}
                  onChange={(e) => this.handleItemInputChange(e) }
                  onBlur={ this.handleItemFormSubmit }
                />                  
                <label htmlFor="itemNrMain">Koi Nr.</label>
              </div>              
      </form>
    )
  }










  onMessage = (msgObj) => {
        this.growl.show(msgObj);    
  }

  onStaticMessage = (msgObj) => {
        this.messages.show(msgObj);
  }

  onMessageClear = () => {
        this.growl.clear()
  }


  async componentWillMount() {
    const { auth } = this.props;

    console.log('before fetchBidItem..')

    const { auctionKey, breederKey, itemKey } = this.state
    console.log('componentWillMount:',this.state)

    this.setState({ screenHeight: window.innerHeight })

    //this.onMessage({severity: 'error', summary: 'No Auctions', detail: 'or can not load data' })
    // load auctions from firebase
    const auctions = await getAuctions()
    if (!auctions) {
        this.growl.show({severity: 'error', summary: 'No Auctions', detail: 'or can not load data' });
    } else {
        //this.growl.show({severity: 'success', summary: 'Loaded auctions', detail: 'choose one' })
        this.setState({ auctions: auctions })
    }



    const bidders = await getAllBidders()
    if (!bidders) {
        this.growl.show({severity: 'error', summary: 'No Bidders', detail: 'or can not load data' });
    } else {
        //this.growl.show({severity: 'success', summary: 'Loaded auctions', detail: 'choose one' })
        this.setState({ bidders: bidders })
    }




    this.setState({ dataViewedTS: 0 })


    // get bid items, no need for prevBidItemPath, as no event listeners are active for bid items yet (firebase .on())
    const ok = await this.props.fetchBidItem({ auctionKey, breederKey, itemKey, prevBidItemPath:'' })

    if (ok) {
        this.growl.show({severity: 'success', summary: 'Loaded data', detail: 'for '+auctionKey+' Nr:'+itemKey });
        this.setState({ dataViewedTS: 1 })  // indicate we got new data, but hasn t been viewed yet..
    }

    // should move that into redux.. and create the sorted array there..

    if (!ok) {
      console.log('componentWillMount error or koi not found in db...:', itemKey)

      const catItemOk = this.getCatalogItemForNr(this.state.itemKey)


    }


  }

  render() {

    if (this.props.data === "loading") {
      return (
        <div className="row center-align">
          <div className="col s4 offset-s4">
            <Preloader />
          </div>
        </div>
      );
    }




    // some functions/details only power user can use/see
    const { email } = this.props.auth
    const screenHeight = this.state.screenHeight // because of the tabbar and the menu


    let disabledAddButton = ''
    let showListDetails = true

    if (email !== "yingyingya@gmail.com" && email !== "gukiii@gmail.com") {

      disabledAddButton = true  // not really used
      showListDetails = false

    } else {

      disabledAddButton = false // not really used
      showListDetails = true

    }

    // PROBLEM HERE.. REDUX STORE IS ONLY FOCUSED ON MAX-BIDS... PROBLEMATIC SHOWING CATALOG IMAGES WHICH HAVE NO BIDS (YET)
    // current stuff from redux store for UI
    //const image = !!this.props.data && !!this.props.data.image ? this.props.data.image : "https://www.freeiconspng.com/uploads/no-image-icon-6.png"
    //const itemNr = !!this.props.data && !!this.props.data.id && !!this.props.data.id.itemKey ? this.props.data.id.itemKey : ''

    const image = this.state.image
    const itemNr = this.state.itemKey
    const bidders = this.state.bidders


    // THESE ARE ONLY ACTIVE BIDDERS ALREADY ON RECORD.. WHAT IF I WANT TO ADD A NEW ONE?
    // is set to this.props.data.sortedArr, if there are values for this fish, otherwise set to []
    const biddersForThisItem = !!this.props.data && !!this.props.data.sortedArr && !!this.props.data.sortedArr.length > 0 ? this.props.data.sortedArr.map(item => {
                        return { text: item.bidder, value: item.bidder, markup: `<span>${item.bidder}</span>` };
                      }) : []

    const highestMaxBidder = biddersForThisItem.length > 0 && showListDetails ? this.props.data.sortedArr[0].bidder : ''


    const highestMaxBid = biddersForThisItem.length > 0 ? this.props.data.sortedArr[0].maxBid : 0


    const gotNewBid = highestMaxBid > 0 && this.props.data.received !== this.state.dataViewedTS ? true : false

    const finalPriceNice = !!this.state.finalPrice && !!this.state.finalPrice > 0 ? (<NumberFormat value={this.state.finalPrice} displayType={'text'} thousandSeparator={true} suffix={',000'} />) : ''

    const hadBidsText = showListDetails ? `Bids:${biddersForThisItem.length}` : 'Has Max Bid !!';

    const finalPriceWin = !!this.state.finalPriceWin ? this.state.finalPriceWin : false

    const preFilledFinalPrice = finalPriceWin? highestMaxBid : '';


    if (this.props.data === "loading") {
      return (
        <div className="row center-align">
          <div className="col s4 offset-s4">
            <Preloader />
          </div>
        </div>
      );
    }
    

    return (
      <div>
        <div id="headerblock" style={{height: HEADER_BLOCK_SIZE, display: "block" }}>
          <div style={{height: FORM_FONT_SIZE, display: "block" }}>
            { this.renderAuctionSelector() }
          </div>
          <div style={{height: INPUT_FONT_SIZE, display: "block" }}>
            { /*this.renderHeader() */ }
            { this.renderInputField() }
          </div>
        </div>

        <div className="mycontainer" style={{  
              backgroundImage: "url(" + this.state.image + ")",
              backgroundPosition: 'center',
              backgroundSize: 'contain',  // cover
              backgroundRepeat: 'no-repeat',
              overflow: "hidden",
              //height: `${window.innerHeight}px`,
              //width: `${window.innerWidth}px`,

              justifyContent: "space-between",
              flexDirection: "column",
              //justifyContent: "center",

              //height: "100vh",
              height: `calc(${screenHeight}px - ${HEADER_BLOCK_SIZE} - ${FOOTER_BLOCK_SIZE})`,
              //paddingTop: "5vh",
              width: "100%",

              display: "flex",
              /*position: "fixed",
              left: 0,
              top: 0,
              margin: 0,
              padding: 0,
  */  
               }}>


          <Growl ref={(el) => this.growl = el} style={{ marginTop: '20%'}}/>
       

          <div style={{ marginTop: '22%', textAlign: 'right' }}>

            { (highestMaxBid > 0) ? (
              <Message severity="warn" text={hadBidsText} />
            ) : (
              <Message severity="info" text="No Bids" />
            )}

            <br />
            <br />

            { (gotNewBid == true) ? (
              <Message severity="error" text={`New Bid !!`} />
            ) : (
              ''
            )}

            <br />
            <br />
            { (finalPriceNice.length > 0 == true && showListDetails) ? (
              <Message severity="info" text={`${finalPriceNice}`} />
            ) : (
              ''
            )}            

            
            
          </div>
        



          <BidListDrawer
            visibleDrawer={ this.state.visibleBIDS }
            bgColor={'#ffb84d'}
            sortedArr={ !!this.props.data && !!this.props.data.sortedArr && this.props.data.sortedArr.length > 0 ? this.props.data.sortedArr : [] }
            closeDrawer={ this.closeVisibleBidsDrawer }
            showBidders={showListDetails}

          />          


          <FinalPrice 
            visibleFB={ showListDetails && this.state.visibleADD } 
            closeFBmodal={ this.closeADDmodal }
            onSubmit={ this.onADDsubmit }
            focusFieldID={"bidder"}
            getImageForNr={ this.getImageForNr }
            onMessage={ this.onMessage }
            values={{ 
              title:"Enter Max Bid",
              priceTitle: "Max Bid",
              price:'', 
              bidder: '', 
              bidders: this.state.bidders,
              showBidders: showListDetails,
              itemNr: itemNr,  
              image: image, 
              bgColor: "#99ddff",
              direction: 'left',
            }} 
          />


          <FinalPrice 
            visibleFB={ this.state.visibleFB } 
            closeFBmodal={ this.closeFBmodal }
            onSubmit={ this.onFBsubmit }
            focusFieldID={"bidder"}
            getImageForNr={ this.getImageForNr }
            onMessage={ this.onMessage }
            values={{ 
              title: `${finalPriceWin? "Enter Our Winner" : "Enter Final Price"}`,
              priceTitle: "Final Price",
              price: preFilledFinalPrice, 
              bidder: highestMaxBidder, 
              bidders: bidders,
              showBidders: showListDetails,
              itemNr: itemNr,  
              image: image, 
              bgColor: `${finalPriceWin? "#ffb3b3" : "#d6f5d6"}`,
              direction: 'right',
              finalPriceWin
            }} 
          />


  

        </div>


        <div className="main-buttons" style={ cssButtonContainer } >


          <Button
            onClick={ this.openVisibleBidsDrawer } 
            label="$" icon="pi pi-list"
            className={ `${highestMaxBid > 0 ? 'p-button-warning' : 'p-button-secondary'} p-button-raised p-button-rounded` }
            style={{ opacity: this.state.visibleBIDS ? 0.2 : 1 }}
          />


          <Button
            onClick={(e) => this.openADDmodal(e) }
            label="Bid" icon="pi pi-plus-circle"
            className="p-button-primary p-button-raised p-button-rounded"
            disabled={ showListDetails? "" : "disabled" }
          />

          <Button 
            onClick={(e) => this.openFBmodal(e, false) }
            label="Final" icon="pi pi-dollar"
            className="p-button-success p-button-raised p-button-rounded"
          />


          <Button 
            onClick={(e) => this.openFBmodal(e, true) }
            label="Win" icon="pi pi-dollar"
            className="p-button-danger p-button-raised p-button-rounded"
          />

          <Button
            onClick={this.props.signOut}
            label="Exit" icon="pi pi-sign-out"
            className="p-button-secondary p-button-raised p-button-rounded"
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = ({ data, auth }) => {
  return {
    data,
    auth
  };
};

export default connect(mapStateToProps, actions)(BidList);


const cssImageCenter = {
  display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "auto",
    height: "300px",
}


const cssButtonContainer = {
  display: "block",
  textAlign: "right",
  height: FOOTER_BLOCK_SIZE,
  paddingBottom: "0.5em"
}

/*

          <div className="" style={{ display: `${this.state.visibleBIDS ? '' : 'none'}` }}>  
            { bidContent }
          </div>





const cssButtonContainer = {

  justifyContent: "flex-end",
  display: "flex",
  //height: "5vh",
  paddingBottom: "0.5em"
}
*/


//             onPointerUp={ e => { this.setState({ visibleBIDS: false })  }}            



/*
      <form onSubmit={this.handleItemFormSubmit}>
        <br />
        <br />
        <div className="input-field col s12">
          <input id="itemNrMain" type="number" 
            className="validate" 
            style={{ textAlign: "right", fontWeight: "900", fontSize: "1.6em", background: "transparent",  }} 
            onFocus={this.handleItemFocus}
            value={this.state.itemKeyInput}
            onChange={ this.handleItemInputChange }
            />                  
          <label htmlFor="itemNrMain" style={{ fontWeight: "900" }}>Koi Nr.</label>
        </div>


              <div className="p-float-label">
                <input id="itemNrMain" type="number" 
                  style={{ textAlign: "center", fontSize: "1.6em" }} 
                  onFocus={this.handleItemFocus}
                  value={this.state.itemKeyInput}
                  onChange={(e) => this.handleItemInputChange }
                />                  
                <label htmlFor="itemNrMain">Koi Nr.</label>
              </div>                

      </form>







                <div className="input-field inline" style={{ width:`calc(100% - 5em)` }}>
                    <label>Código de Barras</label>
                    <input type="text" placeholder="" style={{ textAlign: "right", fontSize: "24px" }} />
                </div>
                <div className="right" style={{ width:`50px`, fontSize: "15px", paddingTop: "2.2em" }}>
                     ,000
                </div>





          <button
            onClick={() => this.setState({ addFormVisible: !addFormVisible })}
            className="btn-floating btn-large teal darken-4"
          >
            {addFormVisible ? (
              <i className="large material-icons">close</i>
            ) : (
              <i className="large material-icons">add</i>
            )}
          </button>


          <button
            onClick={() => this.setState({ activeItemFormVisible: !activeItemFormVisible })}
            className="btn-floating btn-large teal darken-4"
          >
            {activeItemFormVisible ? (
              <i className="large material-icons">close</i>
            ) : (
              <i className="large material-icons">Item</i>
            )}
          </button>







            <BidAdd
              visibleFB={ this.state.visibleADD } 
              closeFBmodal={ this.closeADDmodal }
              onSubmit={ this.onADDsubmit }
              focusFieldID={"bidder"}
              disabled={ disabledAddButton ? "disabled" : ""}
              values={{ 
                title:"Add Max Bid",
                price:2000004, 
                bidder:"Charly", 
                itemNr:itemNr,  
                image:image, 
              }} 
            />    
*/