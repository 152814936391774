import { FETCH_BIDITEM, FETCH_BIDITEM_FAILED } from "../actions/types";

/*
current item: (should remember nr of current item..)
payload: (dataset coming in from firebase)
*/

export default (state = "loading", action) => {
  switch (action.type) {
    case FETCH_BIDITEM:
      return action.payload;
    case FETCH_BIDITEM_FAILED:
      return {};
    default:
      return state;
  }
};
