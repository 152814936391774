import React, { Component } from "react";
//import ToDoList from "./components/ToDoList";

import BidList from "./components/BidList"


import SignIn from "./components/SignIn";
import requireAuth from "./components/auth/requireAuth";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUser } from "./actions";

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


/*
const cssImgBk = (imgUrl='') => {

  return {
      background: `url(${imgUrl}) no-repeat center center fixed`,
      backgroundSize: "cover",
      height: "100%",
      overflow: "hidden",
  }
}
*/



class App extends Component {
  componentWillMount() {
    this.props.fetchUser();
  }

  //           <Route path="/app" component={requireAuth(ToDoList)} />


  render() {
    return (
      <BrowserRouter> 
        <div className="" >
          <Route exact path="/" component={SignIn} />
          <Route path="/app" component={requireAuth(BidList)} />
        </div>
      </BrowserRouter>
    );
  }
}
// style={cssImgBk('http://www.oldboy-village.com/momotaro-koi/upload/carp/41/930_thum_j.jpg')}
export default connect(null, { fetchUser })(App);
