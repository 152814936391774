import React, { Component } from "react";


import NumberFormat from 'react-number-format'

import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';


import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';

// props:
// vars: bgColor, sortedArr, visibleDrawer
// funcs: closeDrawer

const BidListDrawer = ({ sortedArr, bgColor, visibleDrawer, closeDrawer, showBidders=false }) => {


    const renderOneLiner = () => {
	    const { time, maxBid } = sortedArr[0]
	    const timePretty = new Date(time).toLocaleString()
	    const maxBidPretty = (<NumberFormat value={maxBid} displayType={'text'} thousandSeparator={true} suffix={',000'} />)
	    
	    let oneArr = []
	    oneArr.push({ time: timePretty, maxBid: maxBidPretty })

	    return(
			<Sidebar visible={ visibleDrawer} 
				position={'bottom'} 
				onHide={ closeDrawer }
				className="ui-sidebar-md"
				showCloseIcon={false}
				style={{ backgroundColor: bgColor, height: "20%" }}
				blockScroll={true}
			>	    	
	            <DataTable value={oneArr} style={{ background: "transparent" }} bodyStyle={{ background: "transparent" }} >
	                <Column field="time" header="Time" />
	                <Column field="maxBid" header="maxBid" style={{textAlign:'center'}}   />
	            </DataTable>
	        </Sidebar>
	    )      	
    }


    if (!!sortedArr && sortedArr.length > 0) {

    } else {
      
      return(<div>no data</div>)
    }


    if (!showBidders) {
    	return renderOneLiner()
    }



    const formattedArr = sortedArr.map((item, key) => {

        if (!!item.maxBid && !!item.time && !!item.bidder) {
          const timeNice = new Date(item.time).toLocaleString()
          const maxBidNice = (<NumberFormat value={item.maxBid} displayType={'text'} thousandSeparator={true} suffix={',000'} />)
          const uniqueKey = `${item.bidder}_${item.maxBid}_${item.time}`
          return { key:{uniqueKey}, time: timeNice, bidder: item.bidder, maxBid: maxBidNice };          
        }
        return null
    })
    

    return (
		<Sidebar visible={ visibleDrawer} 
			position={'bottom'} 
			onHide={ closeDrawer }
			className="ui-sidebar-md"
			showCloseIcon={false}
			style={{ backgroundColor: bgColor, height: "50%" }}
			blockScroll={true}
		>



            <DataTable value={formattedArr} 
            	style={{ background: "transparent" }} 
            	bodyStyle={{ background: "transparent"  }}
            	scrollable={true} scrollHeight="100%" 
            >
	                <Column field="time" header="Time" sortable={true}  />
	                <Column field="bidder" header="Bidder" style={{textAlign:'center'}} />
	                <Column field="maxBid" header="maxBid" style={{textAlign:'center'}} sortable={true}  />
            </DataTable>		


		</Sidebar>
    )	
}


export default BidListDrawer


