import { todosRef, authRef, provider } from "../config/firebase";
import { FETCH_TODOS, FETCH_USER } from "./types";

import { allBiddersRef, allLogsRef, allAuctionsRef, allCatalogsRef, allMaxBidsRef, firebaseTimestamp  } from "../config/firebase";
import { FETCH_AUCTIONS, FETCH_ALL_MAXBIDS, FETCH_BIDITEM, FETCH_BIDITEM_FAILED } from "./types"




export const getImageFromCatalog = async(itemPath) => {

  try { 
    console.log('^^ itemPath:', itemPath)

    const imageOnce = await allCatalogsRef.child(itemPath).once("value")
    if (imageOnce.exists() === false) {
      console.log('!! ^^ image not found in catalog... maybe wrong itemKey, stopping..:', itemPath)
      return false
    }
    console.log('^^ got image, getting val..')


    const image = await imageOnce.val().image
    console.log('^^ getting image from catalog:', image)

    //const image = await imageOnce.val('image')
    //console.log('^^ getting image from catalog:', image)

    return image
  }
  catch(err) {
    console.log('error getting image from catalog, maybe wrong itemKey:', itemPath)
    return false
  }

}



const logOldBid = async(itemPath, bidObj) => {

  try { 
    // itemPath = `${auctionKey}/items/${breederKey}/${itemKey}` 

    const logPath = `${itemPath}/logs/${bidObj.bidder}/${bidObj.time}`
    console.log('^^ logPath:', logPath)

    const save = await allLogsRef.child(logPath).set(bidObj)

    return save
  }
  catch(err) {
    console.log('error writing bid log:', err)
  }

}


// get all auctions from DB
export const getAuctions = async() => {

  try {

    const snapshot = await allAuctionsRef.once("value")

    console.log('^^ got snapshot:', snapshot)


    // if ( snapshot.exists() ) { 
    const auctions = !!snapshot? await snapshot.val() : {}
    if (auctions == {}) {
      return false
    }

    let arr = []
    for (const key of Object.keys(auctions)) {
        console.log(key, auctions[key]);
        arr.push({ label: key, value: key, breeder: auctions[key].breeder, uri: auctions[key].uri })
    }

    const sortedArr = arr.sort()

    return sortedArr

/*
    const auctionsArr = Object.values(auctions)
    console.log('^^^^auctionsArr:', auctionsArr)

    auctionsArr.map(item => {
        return { label: item.
          {label: 'momo3nd2018', value:'momo3nd2018'}, 
        }
    })


    const sortedAuctions = auctionsArr.sort()


    return sortedAuctions
 */   

  }
  catch(err) {
    console.log('!!! error loading auctions..:', err)
    return false
  }  

}




// get all bidders from DB
export const getAllBidders = async() => {

  try {

    const snapshot = await allBiddersRef.once("value")

    console.log('^^ got snapshot:', snapshot)


    // if ( snapshot.exists() ) { 
    const bidders = !!snapshot? await snapshot.val() : {}
    if (bidders == {}) {
      return false
    }

    let arr = []
    for (const key of Object.keys(bidders)) {
        console.log(key, bidders[key]);
        arr.push({ label: key, value: key, markup: key })
    }

    const sortedArr = arr.sort()

    return sortedArr

  }
  catch(err) {
    console.log('!!! error loading bidders..:', err)
    return false
  }  

}




// bidderKey can be an existing customer, just the number of a bidder or whatever
// if its an exisitng customer, i need to mark it as such.. and also indicate it in the maxBids section (winning bid)
// "win" indicates it was myOwnCustomer (my win)
export const addFinalPriceToMaxBids = async ({ auctionKey, breederKey, itemKey, bidderKey, finalPrice, finalPriceWin=false }) => {

  try {
    //auctionRef.update({ [bidderKey]: maxBid })  // works


    const itemPath = `${auctionKey}/items/${breederKey}/${itemKey}`

    const bidItem = allMaxBidsRef.child(itemPath)
    const snapshot = await bidItem.once("value")

    //const bidItemTrans = allMaxBidsRef.ref(`${auctionKey}/items/${breederKey}/${itemKey}`)


    console.log('^^ got snapshot:', snapshot)


    // if ( snapshot.exists() ) { 
    let transItem = !!snapshot? await snapshot.val() : {}

    if (!!!transItem || !!!snapshot) {
      return null
    }


    transItem.finalPrice = finalPrice
    transItem.bidderKey = bidderKey
    transItem.time = firebaseTimestamp()

    if (!!finalPriceWin) {
      transItem.finalPriceWin = true
    }

    // check if we have an image.
    if (!!!transItem.image) {
      console.log('missing the image in maxItem table, probably error item.. quitting')
      return false
    }


    console.log('####### about to set final price to maxprice transItem:', transItem)
    const addItemResult = await bidItem.set( transItem )

    console.log('### addFinalPrice to MaxBids result:', addItemResult)




    return addItemResult

  
    // not sure if i can do set 2x or if i should refetch data after the first fetch..


  }
  catch(err) {
    console.log('!!! error adding final price to max bids:', err)
  }  
} 



// bidderKey can be an existing customer, just the number of a bidder or whatever
// if its an exisitng customer, i need to mark it as such.. and also indicate it in the maxBids section (winning bid)
// "win" indicates it was myOwnCustomer (my win)
export const addFinalPrice = async ({ auctionKey, breederKey, itemKey, bidderKey, finalPrice, finalPriceWin=false }) => {

  try {
    //auctionRef.update({ [bidderKey]: maxBid })  // works

    const itemPath = `${auctionKey}/items/${breederKey}/${itemKey}`

    const catalogItem = allCatalogsRef.child(itemPath)
    const snapshot = await catalogItem.once("value")

    //const bidItemTrans = allMaxBidsRef.ref(`${auctionKey}/items/${breederKey}/${itemKey}`)


    console.log('^^ got snapshot:', snapshot)


    // if ( snapshot.exists() ) { 
    let transItem = !!snapshot? await snapshot.val() : {}


    transItem.finalPrice = finalPrice
    transItem.bidderKey = bidderKey
    transItem.time = firebaseTimestamp()

    if (!!finalPriceWin && finalPriceWin) {
      console.log('ADDING FINAL PRICE TO MAX BIDS..')
      transItem.finalPriceWin = true
      const res = await addFinalPriceToMaxBids({ auctionKey, breederKey, itemKey, bidderKey, finalPrice, finalPriceWin:true })
    }

    // check if we have an image.
    if (!!!transItem.image) {
      console.log('missing the image in catalog, probably error item.. quitting')
      return false
    }


    console.log('## addFinalPrice transItem:', transItem)
    const addItemResult = await catalogItem.set( transItem )

    console.log('### addFinalPrice result:', addItemResult)




    return addItemResult

  
    // not sure if i can do set 2x or if i should refetch data after the first fetch..


  }
  catch(err) {
    console.log('!!! (outer) error addFinalPrice:', err)
  }  
}  






export const addMaxBid = async ({ auctionKey, breederKey, itemKey, bidderKey, maxBid }) => {

  try {
    //auctionRef.update({ [bidderKey]: maxBid })  // works

    const itemPath = `${auctionKey}/items/${breederKey}/${itemKey}`

    const bidItem = allMaxBidsRef.child(itemPath)
    const snapshot = await bidItem.once("value")

    //const bidItemTrans = allMaxBidsRef.ref(`${auctionKey}/items/${breederKey}/${itemKey}`)


    console.log('^^ got snapshot:', snapshot)


    // if ( snapshot.exists() ) { 
    let transItem = !!snapshot? await snapshot.val() : {}


    if (!!transItem && !!transItem.bids) {
      console.log('got transaction and bids folder')


      console.log('checking for race condition..')
      // DUPLICATE CHECK, RACE CONDITION CHECK..
      // check if last bid of bidder is same as new one
      if (transItem.bids[bidderKey] && transItem.bids[bidderKey].maxBid == maxBid) {
        console.log('last Bid == new Bid of user:', bidderKey, maxBid, ' .. stopping')
        return false
      }


    } else {
      console.log('no bids folder yet..')

      // adding image and two empty folders to the new "transaction" object
      transItem = { bids: {} }
    }


    // check if we have an image.
    if (!!!transItem.image) {
      console.log('missing the image..')

      // for some image object can include other data (size, number, variety, ... if its not stated on the photo)
      const image = await getImageFromCatalog(itemPath)

      if (!image) {
        console.log('..no image found in catalog.. stopping!!')
        return false
      }

      transItem = {...transItem, image }
    }



    const oldBid = !!transItem.bids[bidderKey] ? transItem.bids[bidderKey] : {}
    const newBid = { bidder: bidderKey, maxBid, "time": firebaseTimestamp() }

    // if there s no oldBid, indicate that this is a firstBid (for UI), also means there r no log entries for this bidder yet
    transItem.bids[bidderKey] = !!oldBid.maxBid ? newBid : { ...newBid, "firstBid": true }


    console.log('## addMaxBid transItem:', transItem)
    const addBidResult = await bidItem.set( transItem )

    console.log('### addBidResult:', addBidResult)


    // after saving new bid was successful, log the old bid..
    if (oldBid) {
      logOldBid(itemPath, oldBid)
    }



    return addBidResult

  
    // not sure if i can do set 2x or if i should refetch data after the first fetch..


  }
  catch(err) {
    console.log('!!! (outer) error addMaxBid:', err)
  }  
}  


      // ../logs/cn1/
      //      23423443/
      //        maxBid, bidder,
      //      54465456/
      //      55766576/
      // ../logs/tw1/
      //      23423442/



export const fetchLogForItemAndBidder = async ({ auctionKey, breederKey, itemKey, bidderKey }) => {

  try {
    //auctionRef.update({ [bidderKey]: maxBid })  // works


    const itemPath = `${auctionKey}/items/${breederKey}/${itemKey}`
    const logPath = `${itemPath}/logs/${bidderKey}`

    const logItem = allLogsRef.child(logPath)
    const snapshot = await logItem.once("value")


    //const sortedByTime = await snapshot.child("bids").orderByChild("time").once("value")


    console.log(`== fetchLogsForItemAndBidder: ${itemKey}:${bidderKey} ==`)
    console.log('^^ got snapshot')


    if ( snapshot.exists() ) {  

      console.log('^^log existed..')

      const snapVal = await snapshot.val()
      console.log('^^ getting value of snapshot:', snapVal)


      const logArray = Object.values(snapVal)
      console.log('^^^^logArray:', logArray)


      let logArr2 = []
      for (let [key, value] of Object.entries(snapVal)) {
        //console.log('vv ', new Date(value.time).toLocaleString())
        //console.log("^^ Object.entries "+": "+key+": "+value.time+": "+value.bidder+": "+value.maxBid)

        const timeStr = new Date(value.time).toLocaleString()
        logArr2.push( { ...value, timeStr })
      }    

      const reverseArray = logArr2.reverse()
      console.log("##reverseArray:", reverseArray)  

      return reverseArray


    } else {

      console.log('no logs for bidder and item:', itemKey, bidderKey)
      return false
    }

  }
  catch (err) {
    console.log('!!! ^^ error getting logs for item n bidder:', itemKey, bidderKey)

    return false
  }
}



  const toSortedArr = (data) => {

    if (!!!data || !!!data.bids ) {
      return []
    }

    // const peopleArray = Object.keys(peopleObj).map(i => peopleObj[i])
    
    const maxArray = Object.values(data.bids)
    console.log('^^^^maxArray:', maxArray)


    const sortedMaxArray = maxArray.sort((a, b) => {
      return b.maxBid - a.maxBid
    })

    return sortedMaxArray

  }





// gets all bids for an item (simpler version of "fetchBidsForItem")
export const fetchBidItem = ({ auctionKey, breederKey, itemKey, prevBidItemPath='' }) => async dispatch => {

//export const fetchBidItem = ({ auctionKey, breederKey, itemKey }) => async dispatch => {


/*
  // for testing, so i don t hit firebase so much...
  const tmp = JSON.parse("{\"bids\":{\"cn1\":{\"bidder\":\"cn1\",\"maxBid\":\"50\",\"time\":1542854665423},\"my1\":{\"bidder\":\"my1\",\"firstBid\":true,\"maxBid\":\"58\",\"time\":1542854663001},\"nl1\":{\"bidder\":\"nl1\",\"firstBid\":true,\"maxBid\":\"20\",\"time\":1542854663227},\"tw1\":{\"bidder\":\"tw1\",\"firstBid\":true,\"maxBid\":\"50\",\"time\":1542854663718}},\"image\":\"http://www.oldboy-village.com/momotaro-koi/upload/carp/41/930_thum_j.jpg\",\"id\":{\"auctionKey\":\"momo2nd2018\",\"breederKey\":\"momo\",\"itemKey\":\"1\"},\"received\":1543574347771,\"sortedArr\":[{\"bidder\":\"my1\",\"firstBid\":true,\"maxBid\":\"58\",\"time\":1542854663001},{\"bidder\":\"cn1\",\"maxBid\":\"50\",\"time\":1542854665423},{\"bidder\":\"tw1\",\"firstBid\":true,\"maxBid\":\"50\",\"time\":1542854663718},{\"bidder\":\"nl1\",\"firstBid\":true,\"maxBid\":\"20\",\"time\":1542854663227}]}")


  dispatch({
    type: FETCH_BIDITEM,
    payload: tmp   // adding id, so redux knows where stuff came from.. could also use the itemPath (maybe easier..)
  })


  return true


////////////////
*/


  const itemPath = `${auctionKey}/items/${breederKey}/${itemKey}`

  console.log('fetchBidItem..:', itemPath)


  try {

    const bidItem = allMaxBidsRef.child(itemPath)

    bidItem.on("value", async snapshot => {


      // turn off listener on previous item (no need to get updates anymore)
      if( !!prevBidItemPath && prevBidItemPath.length > 0 && prevBidItemPath !== itemPath) {
        const turnOff = await prevBidItemPath.off()
      }

      console.log('fetchBidItem.. bidItem.on("value"..')

      const exists = await snapshot.exists()

      if (exists) { 

        console.log('fetchBidItem.. snapshot.exists()..')


        const snapVal = await snapshot.val()
        console.log('fetchBidItem.. snapVal..:', snapVal)

        const nowTS = Math.floor(Date.now())
        const sortedArr = toSortedArr(snapVal)

        const reduxObj = { 
          ...snapVal, 
          id: { 
            auctionKey, 
            breederKey, 
            itemKey,
            itemPath, 
          },
          received: nowTS,
          sortedArr,
        }



        console.log('redux item to be put into redux:', reduxObj)

        dispatch({
          type: FETCH_BIDITEM,
          payload: reduxObj   // adding id, so redux knows where stuff came from.. could also use the itemPath (maybe easier..)
        })

        return true

      } else {
        console.log('warning, no data for this item:', itemPath)

        dispatch({
          type: FETCH_BIDITEM_FAILED,
          payload: {}   // adding id, so redux knows where stuff came from.. could also use the itemPath (maybe easier..)
        })        

        return false
      }

    })

  }
  catch(err) {
    console.log('error fetching bids (fetchBidItem) for item:', itemPath)

    dispatch({
      type: FETCH_BIDITEM_FAILED,
      payload: {}   // adding id, so redux knows where stuff came from.. could also use the itemPath (maybe easier..)
    })      

    return false
  }
}






// get item from catalog (not really used yet, but will be soon)
// this does not auto-update, only doing this with items we BID on..
// but could be done in the future..

//export const fetchCatalogItem = ({ auctionKey, breederKey, itemKey, prevBidItemPath='' }) => async dispatch => {
//export const fetchCatalogItemOnce = async ({ auctionKey, breederKey, itemKey }) => {
export const fetchCatalogItemOnce = async (itemPath) => {

  //const itemPath = `${auctionKey}/items/${breederKey}/${itemKey}`
  console.log('^^ itemPath:', itemPath)

  try { 


    const catOnce = await allCatalogsRef.child(itemPath).once("value")
    if (catOnce.exists() === false) {
      console.log('!! ^^ item not found in catalog... maybe wrong itemKey, stopping..:', itemPath)
      return false
    }
    console.log('^^ got catalog item, getting val..')


    const catItem = await catOnce.val()
    console.log('^^ getting item from catalog:', catItem)

    return catItem
  }
  catch(err) {
    console.log('error getting item from catalog, maybe wrong itemKey:', itemPath)
    return false
  }

}


/*
export const addToDo = (newToDo, uid) => async dispatch => {
  todosRef
    .child(uid)
    .push()
    .set(newToDo);
};

export const completeToDo = (completeToDoId, uid) => async dispatch => {
  todosRef
    .child(uid)
    .child(completeToDoId)
    .remove();
};


// gets all todos
export const fetchToDos = uid => async dispatch => {
  
  todosRef.child(uid).on("value", snapshot => {
    dispatch({
      type: FETCH_TODOS,
      payload: snapshot.val()
    });
  });
  
};
*/
export const fetchUser = () => dispatch => {
  authRef.onAuthStateChanged(user => {
    if (user) {
      dispatch({
        type: FETCH_USER,
        payload: user
      });
    } else {
      dispatch({
        type: FETCH_USER,
        payload: null
      });
    }
  });
};

export const signIn = () => dispatch => {
  authRef
    .signInWithPopup(provider)
    .then(result => {
      console.log('## signIn successful:', result)
    })
    .catch(error => {
      console.log(error);
    });
};

export const signOut = () => dispatch => {
  authRef
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch(error => {
      console.log(error);
    });
};



/////////////////////////////////////////////////////////////////////////////////////////



//auctionRef.update({ [bidderKey]: maxBid })  // works

export const fetchBidsForItem = async ({ auctionKey, breederKey, itemKey }) => {

  try {
    //auctionRef.update({ [bidderKey]: maxBid })  // works


    const itemPath = `${auctionKey}/items/${breederKey}/${itemKey}`

    const bidItem = allMaxBidsRef.child(itemPath)
    bidItem.on("value", async snapshot => {


      //const sortedByTime = await snapshot.child("bids").orderByChild("time").once("value")


      console.log(`== fetchBidsForItem: ${itemKey} ==`)
      console.log('^^ got snapshot')


      if ( snapshot.exists() ) {  
        console.log('^^itemKey existed:', itemKey)

        const snapVal = await snapshot.val()
        console.log('^^ getting value of snapshot:', snapVal)



        //const peopleArray = Object.keys(peopleObj).map(i => peopleObj[i])
        const maxArray = Object.values(snapVal.bids)
        console.log('^^^^maxArray:', maxArray)


        const sortedMaxArray = maxArray.sort((a, b) => {
          return a.maxBid < b.maxBid
        })
        console.log('^^^^sorted maxArray:', sortedMaxArray)


      } else {

        return false
      }

    })


  }
  catch (err) {
    console.log('!!! ^^ error getting fetchBidsForItem:', itemKey, err)

    return false
  }
}





const addMaxBidToSnap = async (bidItem, snapshot, obj) => {

  try {


    let transItem = !!snapshot? await snapshot.val() : {}

    const { bidder, maxBid } = obj
    console.log('obj:', obj)  
    console.log('bidItem:', bidItem)
    console.log('snapshot:', snapshot)
    console.log('transItem:', transItem)

    if (!!transItem && !!transItem.bids) {
      transItem = { bids: {}, logs: {} }
      console.log('got transaction and bids folder')
    } else {
      console.log('no bids folder yet.. creating..')


    }


    const newBid = { ...obj, "time": firebaseTimestamp() }

    const oldBid = !!transItem.bids[bidder] ? transItem.bids[bidder] : {}
    const oldLog = !!transItem.logs[bidder] ? transItem.logs[bidder] : {}

    transItem.logs[bidder] = { ...oldLog, ...obj }
    transItem.bids[bidder] = newBid


    console.log('## transItem:', transItem)
    const added = await bidItem.set( transItem )

    console.log('### added:', added)

/*
    bidItem.transaction(transItem => {

      const { bidder, maxBid } = obj
      console.log('obj:', obj)  
      console.log('bidItem:', bidItem)
      console.log('snapshot:', snapshot)
      console.log('transItem:', transItem)

      if (!!transItem && !!transItem.bids) {
        console.log('got transaction and bids folder')
      } else {
        console.log('no bids folder yet.. creating..')

      }

      const newBid = { ...obj, "time": firebaseTimestamp() }

      const oldBid = !!transItem.bids && !!transItem.bids[bidder] ? transItem.bids[bidder] : {}
      const oldLog = !!transItem.logs && !!transItem.logs[bidder] ? transItem.logs[bidder] : {}

      transItem.logs[bidder] = { ...oldLog, ...obj }
      transItem.bids[bidder] = newBid

      return transItem

    })   
    */ 
  

/*

    // also could try to find last entry of that bidder and see if its got the same bid ..
    // only used for testing... !!! 
    const lastRecordOfBidder = await bidItem.child("bids").orderByChild("bidder").equalTo(obj.bidder).limitToLast(1).once("value")
    
    if ( lastRecordOfBidder.exists() ) {  
      // see if we have results on any previous maxBid by this bidder..
      console.log('^^lastRecordOfBidder existed:', lastRecordOfBidder)
    
      const lastBidVal = await lastRecordOfBidder.val()
      const lastBidInnerVal = lastBidVal[ Object.keys(lastBidVal)[0] ]

      console.log('^^lastBidVal[0]:', lastBidVal[ Object.keys(lastBidVal)[0] ].maxBid )

      if (lastBidInnerVal.maxBid === obj.maxBid) {
        // previous maxBid is the same as current one, return early without adding data
        console.log('!! ^^got duplicate result (last bid of bidder):', lastBidVal)
        return false      
      }
    }



    const newObjFolder = await bidItem.child("bids").push()
    console.log('^^pushing new empty bid onto /bids/ASDFASFD:', newObjFolder)

    const jsonObj = { ...obj, "combo": comboObj, "time": firebaseTimestamp() }
    console.log('^^newObj:', jsonObj)

    const newObj = await newObjFolder.set( jsonObj )  
    console.log('^^setting new bid:', newObj)

    return true  */
  }
  catch (err) {
    console.log('!!! ^^ error setting addMaxBidToSnap:', err)

    return false   
  }
}

/*
const addMaxBidToSnap = async (snapshot, obj) => {

  try {

    // combo field for look up of bidder and maxBid.. to avoid adding the same data in case of race condition
    const comboObj = `${obj.bidder}_${obj.maxBid}`

    // also could try to find last entry of that bidder and see if its got the same bid ..
    // only used for testing... !!! 
    const lastRecordOfBidder = await snapshot.child("bids").orderByChild("bidder").equalTo(obj.bidder).limitToLast(1).once("value")
    
    if ( lastRecordOfBidder.exists() ) {  
      // see if we have results on any previous maxBid by this bidder..
      console.log('^^lastRecordOfBidder existed:', lastRecordOfBidder)
    
      const lastBidVal = await lastRecordOfBidder.val()
      const lastBidInnerVal = lastBidVal[ Object.keys(lastBidVal)[0] ]

      console.log('^^lastBidVal[0]:', lastBidVal[ Object.keys(lastBidVal)[0] ].maxBid )

      if (lastBidInnerVal.maxBid === obj.maxBid) {
        // previous maxBid is the same as current one, return early without adding data
        console.log('!! ^^got duplicate result (last bid of bidder):', lastBidVal)
        return false      
      }
    }

    //await snapshot.child("bids").orderByChild("time").limitToLast(3).once("value")


    // check if this combination of bidder and maxBid was stored before, if so, exit..
    // combo field is only used for this query... can be removed if this query is not used..
    //
    //const checkExistance = await snapshot.child("bids").orderByChild("combo").equalTo(comboObj).once("value")
    //if (checkExistance.exists()) {
    //  console.log('!! ^^got duplicate result:', checkExistance.val())
    //  return false
    //}



    const newObjFolder = await snapshot.child("bids").push()
    console.log('^^pushing new empty bid onto /bids/ASDFASFD:', newObjFolder)

    const jsonObj = { ...obj, "combo": comboObj, "time": firebaseTimestamp() }
    console.log('^^newObj:', jsonObj)

    const newObj = await newObjFolder.set( jsonObj )  
    console.log('^^setting new bid:', newObj)

    return true  
  }
  catch (err) {
    console.log('!!! ^^ error setting addMaxBidToSnap:', err)

    return false
  }
}
*/




// itemPath: "dainichi25/items/dainichi"
// itemKey: 101
// itemURI: "http://...jpeg"
/*
export const createMaxBidItem = ({ itemPath, itemKey, itemURI }) => aysnc dispatch => {
  try {
    allMaxBidsRef
      .child(itemPath)
      .set( { itemKey: { uri: itemURI }}, { merge: true } ) // merge document if one already exists (i think this doesn t work wiht relatime DB, only firestore..)
  
      console.log('createMaxBidItem ..ok')
  }
  catch(err) {
    console.log('error addBidItem:', err)
  }
}
*/
/*
  maxBidsRef
    .child(itemKey)
    .push() // creates a new item with auto-id
    .set({ itemURI) 
*/         



/*

export const allAuctionsRef = databaseRef.child("auction")
export const allCatalogsRef = databaseRef.child("catalog")
export const allMaxBidsRef = databaseRef.child("maxBids")




auctionhelper88

auctions/dainichi25/uri

catalogs/dainichi25/items/dainichi/101/image

maxBids/dainichi25/items/dainichi/101/bids/cn1
maxBids/dainichi25/items/dainichi/101/image

*/


// could do this as a transaction.. only update data if new maxBid is > than last one..




/*
// not sure if i ll use this... 
const bidItemExists = async ({ auctionKey, breederKey, itemKey }) => {

  const bidItem = allMaxBidsRef.child(`${auctionKey}/items/${breederKey}/${itemKey}`)

  bidItem.once("value", snapshot => {
    if ( snapshot.exists() ) {
      return snapshot
    } else {
      return false
    }
  })
}


const createBidItem = async ({ auctionKey, breederKey, itemKey }) => {

  const bidItem = allMaxBidsRef.child(`${auctionKey}/items/${breederKey}/${itemKey}`)

  bidItem.once("value", snapshot => {
    if ( snapshot.exists() ) {

    } else {
      // item doesn t exist yet. copy image url over from catalog..

    }


    bidItem
      .child("/bids")
      .push()
      .set( { 
          bidder: bidderKey, 
          maxBid, 
          time: firebaseTimestamp() 
        }, 
        err => {
          if (err) {
            console.log('!!! error setting maxBid:', err)
            return false
          } else {
            console.log('..success setting maxBid!')
            return true
          } 
        } 
      )    



  try {
    auctionMaxBidsRef.set( { itemKey: { uri: itemURI }}, { merge: true } ) // merge document if one already exists
    
    console.log('createBidItem ..ok')
  }
  catch(err) {
    console.log('error addBidItem:', err)
  }
}
*/


/*
  auctionKey: dainichi25
  breederKey: dainichi
  itemKey: 101
  bidderKey: cn1
  maxBid: 120

*/

/*
function toggleStar(postRef, uid) {
  postRef.transaction(function(post) {
    if (post) {
      if (post.stars && post.stars[uid]) {
        post.starCount--;
        post.stars[uid] = null;
      } else {
        post.starCount++;
        if (!post.stars) {
          post.stars = {};
        }
        post.stars[uid] = true;
      }
    }
    return post;
  });
}


        let arr = []
        for (let [key, value] of Object.entries(snapVal.bids)) {
            console.log("^^ Object.entries "+": "+key+": "+value.time+": "+value.bidder+": "+value.maxBid)

            if (arr[value.bidder]) {
              if (arr[value.bidder].time < value.time) {
                arr[value.bidder] = { ...value, key }
              }
            } else {
              arr[value.bidder] = { ...value, key }  // add the key to the array entry for reference..
            }
        }  


        console.log('^^last bid arr:', arr)


        // find highest bid in array (max 1 result, even thou there could be more bidders with this bid)
        let maxB = 0
        const maxBidders = arr.filter(o => {
          console.log('o:', o)
          if (o.maxBid > maxB) {
            maxB = o.maxBid 
            return true
          } 
          return false
        })
        console.log('^^^^maxBidders:', maxBidders, maxB)



        // find highest bid and all bidders bidding with it
        let biggest = { maxBid: 0 }
        let biggestArr = []

        for (var key in arr) {
          if (arr.hasOwnProperty(key)) {
            console.log(arr[key].maxBid);

            if (biggest.maxBid == arr[key].maxBid) {
              biggestArr.push(arr[key])
            }             

            if (biggest.maxBid < arr[key].maxBid) {
              biggest = arr[key]

              biggestArr.length = 0
              biggestArr.push(arr[key])

            }         
          }  
        }



        console.log('latest highest bid:', biggest) //biggest.maxBid, biggest.bidder, biggest.time)
        console.log('largest bids:', biggestArr)
*/

/*
../107/bids/
  cn1/{}
  tw1/{}
../107/logs/
  cn1/[{},{},{}]
  tw1/[{}]
  */

/*
  postRef.transaction(function(post) {
    if (post) {
      if (post.stars && post.stars[uid]) {
        post.starCount--;
        post.stars[uid] = null;
      } else {
        post.starCount++;
        if (!post.stars) {
          post.stars = {};
        }
        post.stars[uid] = true;
      }
    }
    return post;
  });*/






/*
check if document exists and do things if so..

db.doc('collectionName/documentId').ref.get().then((documentSnapshot) => {
  console.log(documentSnapshot.exists);
});


*/






/*
// not used anymore..
export const fetchBidsForItemOld = async ({ auctionKey, breederKey, itemKey }) => {

  try {
    //auctionRef.update({ [bidderKey]: maxBid })  // works



    const bidItem = allMaxBidsRef.child(`${auctionKey}/items/${breederKey}/${itemKey}`)
    bidItem.on("value", async snapshot => {


      //const sortedByTime = await snapshot.child("bids").orderByChild("time").once("value")


      console.log(`== fetchBidsForItem: ${itemKey} ==`)
      console.log('^^ got snapshot:', snapshot)


      if ( snapshot.exists() ) {  
        console.log('^^itemKey existed:', itemKey)

        const snapVal = await snapshot.val()
        console.log('^^ getting value of snapshot:', snapVal)




//        const lastBidVal = await lastRecordOfBidder.val()
//        const lastBidInnerVal = snapVal[ Object.keys(snapVal)[0] ]


        // create a hast-table / associative array with (key=bidder) with the latest bid 
        let arr = []
        for (let [key, value] of Object.entries(snapVal.bids)) {
            console.log("^^ Object.entries "+": "+key+": "+value.time+": "+value.bidder+": "+value.maxBid)

            if (arr[value.bidder]) {
              if (arr[value.bidder].time < value.time) {
                arr[value.bidder] = { ...value, key }
              }
            } else {
              arr[value.bidder] = { ...value, key }  // add the key to the array entry for reference..
            }
        }  


        console.log('^^last bid arr:', arr)


        // find highest bid in array (max 1 result, even thou there could be more bidders with this bid)
        let maxB = 0
        const maxBidders = arr.filter(o => {
          console.log('o:', o)
          if (o.maxBid > maxB) {
            maxB = o.maxBid 
            return true
          } 
          return false
        })
        console.log('^^^^maxBidders:', maxBidders, maxB)



        // find highest bid and all bidders bidding with it
        let biggest = { maxBid: 0 }
        let biggestArr = []

        for (var key in arr) {
          if (arr.hasOwnProperty(key)) {
            console.log(arr[key].maxBid);

            if (biggest.maxBid == arr[key].maxBid) {
              biggestArr.push(arr[key])
            }             

            if (biggest.maxBid < arr[key].maxBid) {
              biggest = arr[key]

              biggestArr.length = 0
              biggestArr.push(arr[key])

            }         
          }  
        }



        console.log('latest highest bid:', biggest) //biggest.maxBid, biggest.bidder, biggest.time)
        console.log('largest bids:', biggestArr)
        return snapVal

      } else {

        return false
      }

    })


  }
  catch (err) {
    console.log('!!! ^^ error getting fetchBidsForItem:', itemKey, err)

    return false
  }
}
*/

/*
    allMaxBidsRef.child(`${auctionKey}/items/${breederKey}/${itemKey}/bids`).update({ [bidderKey]: maxBid }, err => {
      if (err) {
        console.log('promise.. error updating maxBid:', err)
      } else {
        console.log('promise.. success updating maxBid!')

      }
    })
*/







// FETCH_AUCTIONS, FETCH_MAXBIDS, FETCH_BIDITEM


/*
export const fetchAllAuctions = () => dispatch => {
  allAuctionsRef.on("value", snapshot => {
    dispatch({
      type: FETCH_AUCTIONS,
      payload: snapshot.val()
    })
  })
}

export const fetchAuction = (auctionKey) => dispatch => {
  auctionRef.child(auctionKey).on("value", snapshot => {
    dispatch({
      type: FETCH_AUCTIONS,
      payload: snapshot.val()
    })
  })
}

export const fetchAllMaxBids = () => dispatch => {
  allMaxBidsRef.on("value", snapshot => {
    dispatch({
      type: FETCH_AUCTIONS,
      payload: snapshot.val()
    })
  })
}
*/




/*

// check if dataset exists in firebase:


usersRef.child(theDataToAdd).once('value', function(snapshot) {
  if (snapshot.exists()) {
    alert('exists');
  }
});


*/





/*
// turn on event listener for this bid item
export const fetchBidItemTurnON = ({ itemKey }) => dispatch => {
  maxBidsRef.child(itemKey).on("value", snapshot => {

    dispatch({
      type: FETCH_AUCTIONS,
      payload: { ...snapshot.val(), itemKey }   // adding itemkey to data set, could also add timestamp.. 
    })
  })
}

// turn off event listener for this bid item
export const fetchBitItemTurnOFF = (itemKey) => {
  maxBidsRef.child(itemKey).off('value')
}
*/


