import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

import { composeWithDevTools } from "redux-devtools-extension"






const composeEnhancers = composeWithDevTools({
  // Specify custom devTools options
});
const store = createStore(reducers, /* preloadedState, */ composeEnhancers(
  //applyMiddleware(...middleware),
  applyMiddleware(reduxThunk),
  // other store enhancers if any
));






//const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
