import * as firebase from "firebase";

import { FirebaseConfig } from "../config/keys";
firebase.initializeApp(FirebaseConfig);


// good query examples:

// https://www.tutorialspoint.com/firebase/firebase_queries.htm


//export const export db = firebase.database()
const databaseRef = firebase.database().ref();
export const todosRef = databaseRef.child("todos");
export const authRef = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();


export const firebaseTimestamp = () => {
	return firebase.database.ServerValue.TIMESTAMP
}

//const databaseRef = firebase.database().ref()
export const allAuctionsRef = databaseRef.child("auctions")
export const allBiddersRef = databaseRef.child("bidders")

export const allCatalogsRef = databaseRef.child("catalogs")
export const allMaxBidsRef = databaseRef.child("maxBids")
export const allLogsRef = databaseRef.child("logs")


/*
export const auctionRef = allAuctionsRef.child("dainichi25")
export const auctionMaxBidsRef = auctionRef.child("maxBids")
export const bidItemRef = auctionMaxBidsRef.child(105) 
*/

//export const authRef = firebase.auth()
//export const provider = new firebase.auth.GoogleAuthProvider()

//console.log('databaseRef:', databaseRef)
//console.log('allAuctionsRef:', allAuctionsRef)
//console.log('auctionRef:', auctionRef)
//console.log('bidItemRef:', bidItemRef)



/*
auctionMaxBidsRef.once("value", function(snapshot) {
  console.log('1) bidItemRef.once..');
  if (!!snapshot) { //|| snapshot.exists() == false) {
  	return
  }
  snapshot.forEach(function(child) {
  	const cVal = child.val()
    console.log("*1) auctionMaxBidsRef.once:"+child.key+": "+child.value);
  });
});
*/





/*
auctionMaxBidsRef.("value", function(snapshot) {
  console.log('1 evt) bidItemRef..');
  snapshot.forEach(function(child) {
    //console.log("*1 evt) auctionMaxBidsRef:"+child.key+": "+child.val());
  });
});

*/










/*  ALSO VERY NICE, AND WITH .ON VALUE  !!!
const startBidListener = async (bidPath) => {
	const eventRef = firebase.database().ref(bidPath)
	await eventRef.on('value', snapshot => {
		const resValue = snapshot.val()

		// better sort by bid amount as well..
		for (let [key, value] of Object.entries(resValue)) {
		    console.log("## evt only for "+snapshot.key+": "+key+": "+value)
		}

	}) // !!!!!! .on  

	return eventRef  // used for removing the event listener (need same ref for that..   ref.off('value')  )
}



// remove event listener for ref.on('value') 
const removeBidListener = (ref) => {
	ref.off('value')
}


const oneBidRef = startBidListener('auction/dainichi25/maxBids/105')
// find out how to turn off the listener as well..


// removeBidListener(oneBidRef)
// oneBidRef.off('value')


*/













/*
const testOnce = async() => {
	const onceBidRes = await firebase.database().ref('auction/dainichi25/maxBids/104').once('value', snapshot => {
	    let onceBid = snapshot.val()
	    console.log('*2) onceBid async:',onceBid)
	    return onceBid
	})	
	const v = await onceBidRes.val()
	return v
}

const testOnceVal = testOnce()
console.log('**2 v0: ', testOnceVal)


testOnce().then(b => { console.log('**2) inline testOnce function:', b) } )
*/




/* works ok..
const getOnceBid = async (bidPath) => {
	const eventRef = firebase.database().ref(bidPath);
	const snapshot = await eventRef.once('value');
	const resValue = await snapshot.val();

//	console.log('*3 value:', resValue)

	for (let [key, value] of Object.entries(resValue)) {
	    console.log("*g): "+key+": "+value)
	}

	return resValue
}





getOnceBid('auction/dainichi25/maxBids/104').then(b => { console.log('**3) getOnceBid function:', b) } )
		


const testAwait = async() => {
	const myBid = await getOnceBid('auction/dainichi25/maxBids/104')
	console.log('**4) result of call getOnceBid:', myBid)	
	return myBid
}

testAwait().then(b => { console.log('***4) testAwait function:', b) } )

*/




/*
// .on(child_changed) only works with LISTS (that were created with PUSH and have auto-created id) => this does not work here
firebase.database().ref('auction/dainichi25/maxBids/105').on("child_changed", snap => {  // was "child_added" before
  snap.forEach(child => {
  	console.log("*5) mxBids.on child_changed:"+child.key+": "+child.val());
  })
});

*/
	


/*
  bidItemRef.on("value", snapshot => {
    console.log("bidItemRef result:",  snapshot.val() )
  })
*/